@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'),
    url('HelveticaNeueCyr-Roman.woff2') format('woff2'),
    url('HelveticaNeueCyr-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'),
    url('HelveticaNeueCyr-Medium.woff2') format('woff2'),
    url('HelveticaNeueCyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'),
    url('HelveticaNeueCyr-Bold.woff2') format('woff2'),
    url('HelveticaNeueCyr-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: 'swap';
}

@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Semibold.woff2') format('woff2'),
    url('Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('MonumentExtended-Regular.woff2') format('woff2'),
    url('MonumentExtended-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('MonumentExtended-Bold.woff2') format('woff2'),
    url('MonumentExtended-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
